const payments = [
  {
    text: "MTN",
    enable: true,
    type: "MTNZM",
    merchantId: 15,
    img: "../assets/images/home/MTN.png",
    checkPrecedence: (phone) => {
      let phoneHeader = parseInt(phone.substring(0, 3), 10);
      if (
        (phoneHeader >= 760 && phoneHeader <= 769) ||
        (phoneHeader >= 960 && phoneHeader <= 969)
      ) {
        return true;
      }
      return false;
    },
  },
  {
    text: "Airtel",
    enable: true,
    merchantId: 15,
    type: "AIRTELZM",
    img: "../assets/images/home/airtel_money.png",
    checkPrecedence: (phone) => {
      let phoneHeader = parseInt(phone.substring(0, 3), 10);
      if (
        (phoneHeader >= 770 && phoneHeader <= 779) ||
        (phoneHeader >= 970 && phoneHeader <= 979)
      ) {
        return true;
      }
      return false;
    },
  },
  {
    text: "Zamtel",
    enable: true,
    merchantId: 15,
    type: "ZAMTEL",
    img: "../assets/images/home/zamtel.png",
    checkPrecedence: (phone) => {
      let phoneHeader = parseInt(phone.substring(0, 3), 10);
      if (
        (phoneHeader >= 750 && phoneHeader <= 759) ||
        (phoneHeader >= 950 && phoneHeader <= 959) ||
        (phoneHeader >= 211 && phoneHeader <= 218)
      ) {
        return true;
      }
      return false;
    },
  },
];
export default {
  payments: payments.filter((payment) => payment.enable),
};
