import HomeHeaderOther from "@/components/HomeHeader/indexOther";
import InstallBonusPopup from "@/components/InstallBonusPopup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { LocationInfo } from "@/data/LocationInfo";
import { PersistContext } from "@/data/PersistProvider";
import { LanguageContext } from "@/data/LanguageProvider";
import { useEnhancedHistory } from "@/data/RouterProvider";
import { BindUser, SendEvent } from "@/utils/Analytics";
import { FromGameApp, FromStandAlone } from "@/utils/BroswerHelper";
import { SendCMD } from "@/utils/HTTPRequest";
import { ChannelRemind } from "@/utils/UrlHelper";
import React, { useEffect } from "react";
import AuthCode from "react-auth-code-input";
import { useLocation } from "react-router-dom";
import { CountDown, Toast } from "react-vant";
import style from "./style.less";
import { LocalConfig } from "@/config";

function filterNonDigits(input) {
  const result = input.replace(/\D/g, "");
  return result;
}
const reSendTime = 60000;

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SendRegCode = () => {
  const history = useEnhancedHistory();
  const query = useQuery();
  const [codeError, setCodeError] = React.useState("");
  const [code, setCode] = React.useState("");
  const [formCorrect, setFormCorrect] = React.useState(false);
  const { getPersist, setPersist } = React.useContext(PersistContext);
  const { langText } = React.useContext(LanguageContext);
  const nowRegionInfo = LocationInfo();
  const phone = query.get("phone");
  const [bonus, setBonus] = React.useState(0);
  const fullPhone = nowRegionInfo.phoneStartsWith + phone;
  //   const fullPhone = "86" + phone;
  const password = query.get("password");
  const agentRid = getPersist("agent_rid") ? getPersist("agent_rid") : "";
  const guid = getPersist("guid") ? getPersist("guid") : "";
  const inviteWheelInviter = getPersist("invite_wheel_inviter") ? getPersist("invite_wheel_inviter") : "";
  const abtestTag = getPersist("abtest_tag") ? getPersist("abtest_tag") : "";
  const phoenixClickId = getPersist("phoenix_click_id") ? getPersist("phoenix_click_id") : "";
  const afId = getPersist("af_id") ? getPersist("af_id") : "";
  const attribution = localStorage.getItem("attribution") ? localStorage.getItem("attribution") : "";
  const adSource = localStorage.getItem("ad_source") ? localStorage.getItem("ad_source") : "";
  const attributionId = localStorage.getItem("attribution_id") ? localStorage.getItem("attribution_id") : "";
  const firstUrl = window.getFVURL ? window.getFVURL() : "";
  const firstReferrer = window.getFR ? window.getFR() : "";
  const channel = getPersist("channel") ? getPersist("channel") : "";
  const bgSettings = getPersist("bgSettings");
  const contactType = bgSettings && bgSettings.settings && bgSettings.settings.client_config && bgSettings.settings.client_config.contactType || "";
  const contactNumber = bgSettings && bgSettings.settings && bgSettings.settings.client_config && bgSettings.settings.client_config.contactNumber || "";
  console.log("agentRid", agentRid);
  console.log("phoenixClickId", phoenixClickId);
  console.log("channel", channel);

  const CodeErrorTextConst = langText("verify_tips_3");

  //倒计时， 重新发送按钮动态显示
  const [sendCodeEnable, setSendCodeEnable] = React.useState(false);

  const formSubmit = (e) => {
    if (!code) {
      setCodeError(CodeErrorTextConst);
      return;
    }
    Toast.loading({
      message: "Processing...",
      forbidClick: true,
      duration: 15000,
      onOpened: () => {
        //发送注册请求
        let platform = "1";
        if (FromGameApp) {
          platform = "2";
        }
        if (FromStandAlone) {
          platform = "3";
        }
        SendCMD("register", {
          plat: platform,
          location: nowRegionInfo.code,
          device_type: "h5",
          device_imei: "",
          rolename: phone,
          role_account: fullPhone,
          login_passwd: password,
          referrer: "",
          promocode: "",
          agent_rid: agentRid,
          phoenix_click_id: phoenixClickId,
          af_id: afId,
          abtest_tag: abtestTag,
          attribution: attribution,
          attribution_id: attributionId,
          first_url: firstUrl,
          first_referrer: firstReferrer,
          ad_source: adSource,
          channel: channel,
          code: code,
          invite_wheel_guid: guid,
          invite_wheel_inviter: inviteWheelInviter,
          first_visit_url: localStorage.getItem("first_visit_url"),
        })
          .then((res) => {
            Toast.clear(true);
            setFormCorrect(() => true);
            if (!res.status) {
              console.log(res.errdesc);
              switch (res.errdcode) {
                case "checkVcode_1":
                case "checkVcode_2":
                  SendEvent("sign_up_failed", { phone: phone, reason: "code error" });
                  setCodeError(langText("verify_tips_6"));
                  return;
                case "register_1":
                  SendEvent("sign_up_failed", { phone: phone, reason: "register closed" });
                  setCodeError(langText("verify_tips_7"));
                  return;
                case "register_6":
                case "player_register_1":
                  SendEvent("sign_up_failed", { phone: phone, reason: "register closed by region" });
                  setCodeError(langText("verify_tips_8"));
                  return;
                case "player_register_2":
                  SendEvent("sign_up_failed", { phone: phone, reason: "phone already registered" });
                  setCodeError(langText("verify_tips_9"));
                  return;
                default:
                  SendEvent("sign_up_failed", { phone: phone, reason: res.errdcode });
                  setCodeError(langText("verify_tips_10"));
                  return;
              }
            } else {
              console.log("onRegisterSuccess");
              setPersist("last_phone", phone);
              setPersist("guid", "");
              onLoginSuccess(res);
            }
            console.log(res);
          })
          .catch((err) => {
            Toast.clear(true);
            setFormCorrect(() => true);
            console.log(err);
            setCodeError(langText("verify_tips_11"));
            SendEvent("sign_up_failed", { phone: phone, reason: "network error" });
          });
      },
    });
    setFormCorrect(() => false);
    e && e.preventDefault();
  };

  const onLoginSuccess = (res) => {
    SendEvent("sign_up");
    console.log(res.player_info);
    setPersist("user_info", res.player_info);
    if (res.player_info) {
      //登录后根据用户tag强制设置，也就是说，可能会被清掉，这是预期的。
      setPersist("abtest_tag", res.player_info.abtest_tag);
    }
    setPersist("gatesvr", res.gatesvr);
    BindUser(res.player_info.rid);
    //人如果有bonus，就弹出来，然后再跳转
    if (res.player_info.bonus) {
      setBonus(res.player_info.bonus / 100);
    } else {
      finishRedirect();
    }
  };

  const finishRedirect = () => {
    //如果可以返回，直接返回，否则回到大厅
    let lastUrl = getPersist("login_back_url");
    if (lastUrl) {
      setPersist("login_back_url", null);
      console.log("will back to lastUrl", lastUrl);
      history.replace(lastUrl);
    } else {
      history.replace(ChannelRemind("/home"));
    }
  };

  //再次发送验证码
  const sendAgainSubmit = (e) => {
    if (!phone) {
      // 电话号码为空，跳转到forgot页面
      history.replace(ChannelRemind("/login?reg=1"));
      return;
    }

    //发送验证码请求
    SendCMD("sendVerificationCode", {
      phone: fullPhone,
    }).then((res) => {
      if (!res.status) {
        setCodeError(res.errdesc);
        SendEvent("resend_code_failed");
        return;
      }
      Toast.success("Sent successfully");
      //发送成功的逻辑处理： 展示倒计时
      setSendCodeEnable(false);
      setCodeError("");
      SendEvent("resend_code_success");
    });

    e && e.preventDefault();
  };

  useEffect(() => {
    if (code && !codeError) {
      SendEvent("input_sms_code_complete");
      formSubmit();
    } else {
      setFormCorrect(() => false);
    }
  }, [code, codeError]);

  return (
    <div class={style.send_reg_code_container}>
      <HomeHeaderOther title={langText("profile_btn_15")} />
      <div class={style.verify_top}>
        <p>{langText("verify_tips_1")}</p>
        <p>
          {langText("verify_tips_2")} <span>{phone}</span>
        </p>
      </div>
      <div class={style.verify_form}>
        <div class={style.verify_code_form_inputs}>
          <AuthCode
            length={4}
            autoFocus={true}
            allowedCharacters="numeric"
            onClick={() => {
              SendEvent("click_sms_code_input");
            }}
            onChange={(value) => {
              value = filterNonDigits(value);
              if (value.length >= 4) {
                value = value.slice(0, 4);
              }
              if (value.length == 4) {
                setCode(value);
                setCodeError("");
              } else {
                setCode("");
                setCodeError("");
              }
            }}
          />
        </div>
        <div class={codeError ? style.login_send_reg_code_error : style.login_send_reg_code_info}>
          {codeError ? codeError : CodeErrorTextConst}
        </div>
        <div class={style.verify_bottom}>
          <p>{langText("verify_tips_4")}</p>
          <p>
            {langText("verify_tips_5")}{" "}
            {sendCodeEnable ? (
              <span class={style.send_again_span} onClick={sendAgainSubmit}>
                {langText("verify_btn_1")}
              </span>
            ) : (
              <span class={style.send_count_span}>
                <CountDown
                  style={{
                    "--rv-count-down-text-color": "rgb(245, 253, 86)",
                    lineHeight: "2rem",
                    fontWeight: 700,
                    fontSize: "1rem",
                  }}
                  time={reSendTime}
                  format="sss"
                  onFinish={() => {
                    setSendCodeEnable(true);
                  }}
                />
              </span>
            )}
          </p>
        </div>
        <div
          class={style.code_reg_btn + (formCorrect ? "" : ` ${style.code_reg_btn_disabled}`)}
          type="submit"
          onClick={formSubmit}
        >
          {langText("profile_btn_15")}
        </div>
        <div style={{display:"flex", flexDirection:"row", flexDirection:"center", marginLeft:"0.5rem", marginTop:"1.5rem"}}>
          <img src={require("@/assets/images/home/icon_tanhao.png").default} style={{width:"1rem", height:"1rem", marginLeft:"0.5rem"}} />
          <div style={{color:"rgb(68, 141, 64)", fontWeight:"bolder", fontSize:"0.8rem", marginLeft:"0.3rem", paddingRight:"0.8rem"}}>
            <span>
              {langText("service_tips_1")}
            </span>
            <br/>
            <span>{contactType}:&nbsp;</span>
            <CopyToClipboard
              text={contactNumber}
              onCopy={() => {
                Toast.success({
                  message: "Copy Successfully",
                });
              }}
            >
              <span style={{color:"rgb(112, 219, 106)", textDecoration:"underline"}}>
                @{contactNumber}
                <img style={{width:"1rem", height:"1rem", marginLeft:"0.4rem"}} src={require("@/assets/icons/copy_green.png").default} />
              </span>
            </CopyToClipboard>
          </div>
        </div>
      </div>
      <div class={style.code_footer}>
        <img src={require("@/assets/images/home/18_plus.png").default}/>
        <span>© {LocalConfig.gameName} {langText("common_tips_1")}</span>
      </div>
      <InstallBonusPopup
        showPopup={bonus > 0}
        amount={bonus}
        onClose={() => {
          setBonus(0);
          finishRedirect();
        }}
      />
    </div>
  );
};

export default SendRegCode;
