import enTranslation from '../lang/en.json';
import bemTranslation from '../lang/bem.json';

export default {
    en: {
        name: "en",
        text: "English",
        langJson: enTranslation
    },
    bem: {
        name: "bem",
        text: "Pemba",
        langJson: bemTranslation
    }
};
